<template>
  <div class="tablebody">
    <div class="info">
      <div class="paycount" id="paycount1">{{totalRows}}</div>
      <b-tooltip target="paycount1">Количество поставщиков</b-tooltip>
      <b-btn class='paycount' size="sm" v-on:click="querytabledata"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
    </div>
    <b-container fluid>
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Поиск" size="sm" style="background-color: #c6ced4" />
              <b-input-group-append>
                <b-btn size="sm" :disabled="!filter" @click="filter = ''"><b-icon-backspace></b-icon-backspace></b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table striped hover small show-empty
                table-variant="dark"
                style="color: #ccc"
                stacked="md"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
      >
        <template v-slot:cell(actions)="data">
          <b-btn class='button' v-if="!isGuest" size="sm" @click="supplierDetail(data.item)" style="margin: 0 4px 0 0"> <b-icon-pen> </b-icon-pen> </b-btn>
        </template>
      </b-table>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination pagination-sm :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
        </b-col>
      </b-row>
    </b-container>
    <div>
      <b-modal id="supplierDetail"
                centered
                title="Поставщик"
                size= 'sm'
                buttonSize= 'sm'
                okVariant= 'success'
                @ok= 'updateSupplier'
                :ok-disabled='!(nameState||infoState)'
                auto-focus-button= 'ok'>
        <div role="group">
          <b-form-input
            id="input-live-name"
            v-model="partner.name"
            :state="nameState"
            placeholder="Наименование"
            trim size="sm"
            autofocus
          ></b-form-input>
          <b-form-input
            id="input-live-info"
            v-model="partner.info"
            :state="infoState"
            placeholder="Инфо"
            trim size="sm"
          ></b-form-input>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '../_services/config.json';

export default {
  name: 'suppliers',
  data: () =>  ({
    isBusy: false,
    items: [],
    fields: [
      //{ label: '#', key: 'index', 'class': 'text-center'},
      { label: 'ID', key: "old_id", sortable: true, 'class': 'text-left' },
      { label: 'Наименование', key: "name", sortable: true, 'class': 'text-left' },
      { label: 'Тип', key: "suppliertype", 'class': 'text-left' },
      { label: 'Контакты', key: "contact", 'class': 'text-left' },
      { label: 'Адрес', key: "address", 'class': 'text-left' },
      { label: 'Банк', key: "bank", 'class': 'text-left' },
      { label: 'р/с', key: "account", 'class': 'text-left' },
      { label: 'Сервисов', key: "services", 'class': 'text-left' },
      { label: 'Баланс', key: "balance", 'class': 'text-right' },
      { label: 'Действия', key: 'actions', 'class': 'text-right' }
    ],
    oldName: '',
    oldInfo: '',
    partner: {},
    currentPage: 1,
    perPage: 40,
    totalRows: 0,
    totalsum: 0,
    pageOptions: [ 20, 40, 80 ],
    sortBy: 'name',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
  }),
  computed: {
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    nameState() {
      return this.partner.name!=this.oldName
    },
    infoState() {
      return this.partner.info!=this.oldInfo
    },
    isGuest () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='guest'
      else return false
    },
  },
  methods: {
    supplierDetail (item) {
      this.partner=item;
      this.oldName=item.name;
      this.oldInfo=item.info;
      this.$bvModal.show('supplierDetail')
        //this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updateSupplier () {
      if (confirm("Вы уверены?"))
      axios
      .post(`${config.apiUrl}/query/getdata`,{DB: 'prodDB', partnerId: this.partner.id, name: this.partner.name, info: this.partner.info,
                                              type: ['updatePartner'], token: this.$store.state.authentication.user.token})
      .then(
        console.log('ok')
      )
      .catch(error => {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            this.$store.dispatch('authentication/logout');
            this.$router.push('/')
        } else alert(error)
      });
    },
    querytabledata () {
      axios
          .post(`${config.apiUrl}/query/getdata`,{ DB: 'prodDB', bankId: '', type: ['Suppliers'], token: this.$store.state.authentication.user.token })
          .then(response => {
                  this.items = response.data;
                  this.totalRows = this.items.length;
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert(error)
          });
    }
  },

  created() {
    this.querytabledata ()
  }
}
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
